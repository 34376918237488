.container--auth-page:not(.account-details) {
  // hides mascot image

  @media (max-width: $breakpoint-small) {
    > div + span {
      display: none !important;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1.5rem;
  margin-right: auto;
  margin-left: auto;
  gap: 4rem;
  text-align: left;

  @include screen-size('medium') {
    padding: 3rem;
    gap: $spacing-xxl;
  }

  .alert {
    width: 100%;
    font-size: 0.875rem;

    @include screen-size('small') {
      width: 95%;
      max-width: 275px;
      padding: 0.75rem;
      font-size: 1rem;
    }

    ul {
      padding-left: 0.25rem;
      margin-bottom: 0.25rem;
      list-style-type: none;

      li {
        position: relative;
        margin-top: 0.125rem;

        &:first-of-type {
          margin-top: 0.25rem;
        }
      }
    }
  }
}

.loading {
  @include button-loading-animation;
}

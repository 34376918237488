.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  vertical-align: top;
}

.table thead th {
  border-bottom: 2px solid #dee2e6;
  vertical-align: bottom;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table .thead-dark th {
  border-color: #454d55;
  background-color: #343a40;
  color: #fff;
}

.table .thead-light th {
  border-color: #dee2e6;
  background-color: #e9ecef;
  color: #495057;
}

.table-responsive {
  display: block;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.container--categories {
  flex: 1 0 auto;
  padding-bottom: 5rem;

  h1 {
    @include text-bold;
    @include font-primary;

    color: $secondary-500;
    font-size: 2rem;
  }

  h2 {
    @include text-bold;
    @include font-primary;

    color: $secondary-500;
    font-size: 1.5rem;
    text-align: left;
  }

  p {
    text-align: left;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  section {
    padding-top: 1rem;

    &:first-of-type {
      padding-top: 2rem;
    }

    &.types {
      img {
        width: 100%;
      }
    }
  }

  .guides__card {
    display: flex;
    width: 100%;
    height: 100%;

    a {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-radius: 0.5rem;
      margin-bottom: 1.5rem;
      box-shadow: var(--ring-offset-shadow, 0 0 transparent), var(--ring-shadow, 0 0 transparent),
        0 1px 3px 0 rgb(0 0 0 / 25%), 0 1px 2px 0 rgb(0 0 0 / 10%);

      &:hover {
        text-decoration: none;
      }
    }

    .guides__card__cta {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding: 2rem 1rem 1rem;

      .button-cta {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: flex-end;
        order: 1;
        padding: 0.35rem 0.75rem;
        border-radius: 0.25rem;
        background: transparent;
        color: $secondary-500;
        font-size: 0.875rem;
      }
    }

    img {
      width: 100%;
      border-radius: 0.25rem 0.25rem 0 0;
    }

    .guides__card__content {
      flex-grow: 1;
      padding: 1rem 1rem 0;
      text-align: left;

      h3 {
        @include text-bold;
        @include font-primary;
        margin-bottom: 0;

        color: $grey-900;
        font-size: 1rem;
        line-height: 1.2;

        @include screen-size('medium') {
          font-size: 1.125rem;
        }

        @include screen-size('extra-large') {
          font-size: 1.325rem;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.accordion__button {
  width: 100%;
  padding: 18px;
  border: none;
  background-color: $system-grey-5;
  color: #444;
  cursor: pointer;
  text-align: left;

  &:hover {
    background-color: $background-backdrop;
  }

  &::before {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
    margin-right: 12px;
    content: '';
    transform: rotate(-45deg);
  }
}

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
  color: $secondary-500;
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  border-color: $secondary-500;
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  @include text-normal;
  @include font-secondary;
  padding: 20px;

  animation: fadein 0.35s ease-in;

  p {
    margin-bottom: 0.75rem;
    text-align: left;
  }

  a {
    @include text-bold;
    @include font-secondary;

    color: $secondary-500;
  }

  ul {
    padding-left: 0;
    margin: 0.25rem 0;
    list-style-type: none;
    text-align: left;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

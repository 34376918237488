.container--guides {
  @media screen and (min-width: 576px) and (max-width: 768px) {
    max-width: 768px !important;
  }

  h1 {
    @include text-bold;
    @include font-secondary;
    margin-bottom: 2rem;
    color: $secondary-500;
    font-size: 2rem;
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 2rem;
    color: $grey-900;
    line-height: 1.4rem;

    a {
      @include text-bold;
      @include font-secondary;
      color: $secondary-500;
    }
  }
}

.masonry-grid_col {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;

  > div {
    border-radius: 0.25rem;
    margin-bottom: 30px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
      var(--tw-ring-shadow, 0 0 transparent), 0 1px 3px 0 rgb(0 0 0 / 25%),
      0 1px 2px 0 rgb(0 0 0 / 10%);
  }

  .category__card__content {
    padding: 1rem;
    text-align: left;
  }

  img {
    display: block;
    width: 100%;
    min-height: 129px;
    border-radius: 0.25rem 0.25rem 0 0;
  }

  h2 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 1rem;
    color: $grey-900;
    font-size: 1.3rem;
    line-height: 1.2;
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.2 !important;
  }

  a {
    width: 100%;
    font-size: 1.125rem;
  }
}

.container--deals {
  max-width: 84.5rem;
  padding: 0 $spacing-sm;
  margin: 0 auto;

  &#deal-count {
    padding-top: $spacing-sm;
  }

  @include screen-size('large') {
    width: calc(100dvw - 10rem);
    max-width: 1460px;
    padding: 0;
  }
}

.container--deals-faqs {
  padding: 5rem 0 6.5rem;
  background-color: $grey-900;

  .deals-page__faqs {
    max-width: 100%;
    padding: 0 1.5rem;
    margin: 0 auto;

    @include screen-size('medium') {
      max-width: 752px;
      padding: 0;
    }

    h2 {
      @include display-sm;
      @include text-normal;
      @include font-primary;

      max-width: 240px;
      margin-right: auto;
      margin-bottom: 2rem;
      margin-left: auto;
      color: $white;
      text-align: center;

      @include screen-size('medium') {
        @include display-xl;

        max-width: 464px;
        margin-bottom: 4.75rem;
      }
    }

    .accordion__item {
      border-bottom: 1px solid $grey-800;

      &.accordion__item--last {
        border-bottom: 1px solid $grey-900;
      }
    }

    .accordion__button {
      @include display-xs;
      @include text-normal;
      @include font-primary;

      display: flex;
      justify-content: space-between;
      padding: 1.5rem 0;
      background-color: $grey-900;
      color: $white;
    }

    .accordion__panel {
      @include text-lg;
      @include text-normal;
      @include font-secondary;

      padding: 0 0 1.5rem;
      color: $white;

      a {
        @include text-normal;
        @include font-secondary;
      }
    }

    .accordion__button::before,
    .accordion__button[aria-selected='true']::before {
      display: none;
      width: 0;
      height: 0;
      border: 0;
      margin: 0;
    }

    .accordion__button::after {
      display: inline-block;
      content: '+';
      font-size: 1.5rem;
    }

    .accordion__button[aria-expanded='true']::after,
    .accordion__button[aria-selected='true']::after {
      display: inline-block;
      content: '-';
      font-size: 1.5rem;
    }
  }
}

.background-grey-100 {
  background-color: $grey-100;
}

.container__scroll-area {
  padding: 0;

  .head-stuck {
    & div[class~='sticky-header_vehicle__'] {
      visibility: visible;
    }
  }

  .sticky {
    // position: relative;
    z-index: 1000;
  }

  .stuck {
    z-index: 100;
    transform: none !important;

    .sticky__container {
      display: flex;
    }
  }
}

.deals-page__affiliates {
  background-color: $grey-100;

  & > div {
    max-width: 84.5rem;
    padding: 0 $spacing-sm;
    margin: 0 auto;

    @include screen-size('large') {
      width: calc(100dvw - 10rem);
      max-width: 1460px;
      padding: 0;
    }
  }
}

.deals-page__recommended {
  padding-top: 3.5rem;
  background-color: $grey-100;
}

@import 'shadows';

.container--static-page {
  flex: 1 0 auto;
  padding-top: 2rem;
  padding-bottom: 5rem;

  h1 {
    @include text-bold;
    @include font-primary;

    margin-bottom: 1rem;
    color: $primary-pink;
    font-size: 2rem;
  }

  h2 {
    @include text-bold;
    @include font-primary;

    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  h3 {
    @include text-bold;
    @include font-primary;

    color: $primary-pink;
  }

  h4 {
    @include text-bold;
    @include font-primary;

    margin-top: 0.75rem;
    margin-bottom: 0;
    color: $primary-pink;
    line-height: 1.5;
  }

  h5 {
    @include text-bold;
    @include font-primary;
  }

  p {
    @include text-normal;
    @include font-secondary;

    margin-bottom: 10px;
    line-height: 1.4rem;

    &.text-semibold {
      @include text-bold;
      @include font-secondary;
    }
  }

  a {
    @include text-bold;
    @include font-secondary;

    color: $primary-pink;
    text-decoration: none;

    &:hover {
      color: $primary-pink;
      text-decoration: underline;
    }
  }

  table {
    th {
      @include text-bold;
      @include font-secondary;
    }

    tr:hover {
      background: transparent;
    }
  }

  li {
    @include text-normal;
    @include font-secondary;

    line-height: 1.4rem;
  }

  .sub-nav__container {
    .sticky-nav {
      position: sticky;
      top: 1rem;
      display: grid;
    }

    ul {
      padding: 1rem 0;
      list-style-type: none;
    }

    li {
      margin-bottom: 10px;
    }

    a {
      color: $system-grey;
    }

    a.is-current {
      color: $primary-pink;
    }
  }

  .job-posts {
    margin-top: 1.5rem;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .gradient-background {
    padding: 0.125rem;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    background-image: url('https://assets.leaseloco.com/general/header-separator-background.png');
    background-position: center;
    background-size: contain;
  }

  .white-background {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    background-color: $white;
  }

  ol:not(.list) {
    padding: 0;
    margin: 0;
    counter-reset: item;
    list-style-type: none;

    > li {
      display: table;
      margin-bottom: 0.75rem;
      counter-increment: item;
    }

    > li::before {
      @include text-bold;
      @include font-secondary;

      display: table-cell;
      padding-right: 0.2rem;
      content: counters(item, '.') '.';
    }

    li {
      ol > li {
        margin: 0.5rem 0;
      }

      ol > li::before {
        @include text-normal;
        @include font-secondary;

        content: counters(item, '.') ' ';
      }
    }
  }

  .button-container {
    display: flex;
    gap: 1rem;
  }

  .divider {
    border-color: $grey-200;
  }

  &.pcp-savings-page {
    background-color: $grey-100;

    .divider {
      margin: 2.5rem 0 0;
    }

    .container--pcp-savings-page {
      max-width: 47rem;
    }

    h1 {
      @include display-lg;
      @include text-normal;
      @include font-primary;

      color: $grey-900;
    }

    h2 {
      @include text-xl;
      @include text-normal;
      @include font-primary;

      margin-bottom: 0;
    }

    h3 {
      @include display-xs;
      @include text-bold;
      @include font-primary;

      margin: 2.5rem 0 0.5rem;
      color: $grey-900;
    }

    p {
      @include text-normal;
      @include font-secondary;

      margin-bottom: 1.5rem;
    }

    ol {
      margin-bottom: 1.5rem;

      li {
        @include text-normal;
        @include font-secondary;

        margin-bottom: 0;

        &::before {
          @include text-normal;
          @include font-secondary;
        }
      }
    }

    .table-responsive {
      margin: 2.5rem 0;
    }

    p,
    li {
      @include text-md;
      @include font-secondary;
    }

    .table thead th {
      border-top: 0;
      border-bottom: 0;
    }

    .table td,
    .table th {
      border-top: 1px solid $grey-200;
    }
  }
}

.container--code-of-conduct {
  padding-bottom: 1.5rem;

  span {
    @include text-bold;
    @include font-secondary;

    margin-right: 0.5rem;
    color: $black;
  }
}

.grey-base-layer {
  background-color: $background-backdrop;
}

.vehicle-comparison__content {
  max-width: 1136px;
  padding: 2.5rem 1.5rem 5rem;
  margin: 0 -1rem;
  text-align: left;

  @include screen-size('medium') {
    padding: 2.5rem 0 5rem;
    margin: 0 auto;
  }

  p {
    @include text-md;
    @include text-bold;

    margin-bottom: 1.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    + h1,
    + h2,
    + h3 {
      margin-top: 7.5rem;
    }
  }

  h1,
  h2,
  h3 {
    @include display-sm;
    @include text-bold;

    padding-bottom: 1rem;
    border-bottom: 1px solid $grey-200;
    margin-bottom: 2.5rem;
  }
}

@import 'shadows';

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  position: relative;
  animation-name: fadeInUp;
}

.ReactModal__Content--after-open {
  animation-delay: 200ms;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

.ReactModal__Content--before-close {
  animation-delay: 0ms;
  animation-duration: 400ms;
  animation-name: fadeOutDown;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 800px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 800px, 0);
  }
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  width: 100%;
  height: 100%;
  outline: 0;
}

.modal__top-image {
  position: absolute;
  top: -82px;
  right: 0;
  left: calc(50% - 39px);
  width: 78px;
  height: 81px;

  @include screen-size('medium') {
    top: -123px;
    left: calc(50% - 58.5px);
    width: 117px;
    height: 122px;

    img {
      width: 117px;
      height: 122px;
    }
  }
}

.modal__header {
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;

  @include screen-size('extra-small') {
    padding: 4.5rem 4.5rem 0;
  }

  h2 {
    @include display-sm;
    @include text-normal;
    @include font-primary;
  }

  p {
    @include text-lg;
    @include text-normal;
    @include font-secondary;

    margin-top: 1rem;
  }
}

.modal__content {
  padding: 0;
  margin: 2.5rem 0 0;
  text-align: center;

  @include screen-size('medium') {
    padding: 0 4.5rem;
    margin: 4rem 0 2.5rem;
  }

  .modal__inner > div:last-of-type {
    padding: 2.5rem 1.5rem 1.5rem;

    @include screen-size('medium') {
      padding: 4.5rem;
    }
  }
}

.modal__overlay--deals-exit-intent-modal {
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;

  @include screen-size('small') {
    justify-content: center;
  }
}

.save-to-garage-modal-overlay {
  display: flex;
  height: 100vh;
  justify-content: center;
}

.ReactModal__Content.ReactModal__Content--after-open {
  @include shadow-3xl;
  bottom: 0 !important;
  overflow: visible !important;
  width: 100%;
  min-height: calc(100% - 110px);
  padding: 4.5rem 0 4.6875rem;
  border: 1px solid $grey-200 !important;
  border-radius: 1.5rem 1.5rem 0 0;
  margin-top: 110px;

  background-color: $grey-50;

  @include screen-size('medium') {
    bottom: 75px !important;
    min-height: auto;
    padding: 0;
    border-radius: 2.5rem;
    margin-top: 250px !important;
    margin-bottom: 5rem;
    background-color: $white;
  }

  &.modal__content--no-border {
    border: 0 none !important;
  }

  &.modal__content--no-padding {
    padding: 0;
  }

  &.modal__content--trustpilot-review {
    padding-top: 0 !important;
    border: 0 !important;

    .btn--close {
      top: 1.25rem;
      right: 1.25rem;
      left: auto;
    }
  }

  &.modal__content--confirm-removal-modal {
    position: absolute;
    top: auto;
    min-height: auto;

    @include screen-size('small') {
      left: calc(50% - 22.5rem);
    }

    @include screen-size('medium') {
      position: relative;
      left: auto;
    }
  }

  &.modal__content--save-to-garage-modal {
    position: absolute;
    min-height: auto;
    padding-bottom: 4rem;

    @include screen-size('medium') {
      padding-bottom: 0;
    }
  }

  &.modal__content--tooltip-modal {
    position: fixed;
    min-height: auto;
    padding: 1.5rem 0 2.125rem;

    .btn--close {
      display: none;
    }

    .modal__top-image {
      display: none;
    }
  }

  &.modal__content--deals-exit-intent-modal {
    position: relative;
    z-index: 100000;
    display: flex;
    min-height: initial !important;
    max-height: initial !important;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto !important;
    margin-bottom: 0;

    @include screen-size('small') {
      border-radius: 1.5rem;
      margin-top: 150px;
      margin-bottom: auto;
    }

    @include screen-size('medium') {
      border-radius: 1.5rem;
    }

    .modal__top-image {
      display: none;
    }

    .btn--close {
      top: 1.75rem;
      left: 1.25rem;
    }
  }

  &.modal__content--newsletter-form-modal,
  &.modal__content--save-to-garage-modal {
    top: auto;

    .modal__inner > div:last-of-type {
      padding: 4.5rem 1.5rem 2.125rem;

      @include screen-size('medium') {
        padding: 4.5rem;
      }
    }

    .btn--close {
      top: 1.5rem;
      right: 1.5rem;
    }
  }

  &.one-column--small {
    @include screen-size('medium') {
      left: 0 !important;
      width: 35rem !important;
      margin-left: calc(50% - 17.5rem) !important;
    }
  }
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  position: relative;
  overflow: hidden;
}

.ReactModal__Overlay--after-open {
  z-index: 1000000 !important; // Overriding third party css and above zendesk widget
  backdrop-filter: blur(20px);
  background: rgb(43 40 48 / 40%) !important; // Overriding third party css
  opacity: 1;
}

.modal__overlay--deal-promotion {
  backdrop-filter: none;
  background: $background-overlay !important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-out;

  .image-disclaimer {
    @include text-normal;
    @include font-secondary;

    bottom: -15px;
    margin: 0 auto;
    font-size: 0.75rem;
  }

  .slick-slider {
    img {
      margin: 0 4px;
      cursor: pointer;
    }
  }
}

.btn--close {
  position: absolute;
  z-index: 30;
  top: 1rem;
  right: 1rem;
  width: fit-content;
  cursor: pointer;

  @include screen-size('medium') {
    top: 1.5rem;
    right: auto;
    left: 1.5rem;
  }
}

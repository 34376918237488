.container--rewards {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-image: url('https://assets.leaseloco.com/general/confetti.svg') !important;
  background-position: top !important;
  background-repeat: no-repeat !important;
  background-size: 1330px !important;

  @include screen-size('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-position: bottom !important;
    background-size: 1500px !important;
  }

  h1 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 1rem;

    color: $secondary-500;
    font-size: 2rem;
  }

  p {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  a {
    color: $secondary-500;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .background-white {
    background-color: $white;
  }

  .raf__button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5rem;
    background-color: $white;
    gap: $spacing-sm;
    text-align: center;

    @include screen-size('medium') {
      flex-direction: row;
      justify-content: flex-start;
      gap: $spacing-sm;
    }
  }
}

.rewards-explainer__image-container {
  display: flex;
  height: 100%;
  max-height: 305px;
  align-items: center;
  justify-content: center;

  @include screen-size('small') {
    max-height: 645px;
  }

  img {
    width: auto;
    height: 300px;

    @include screen-size('small') {
      width: 75%;
      height: auto;
    }
  }
}

.spacer {
  width: $wrapper-width;
  max-width: calc(100% - $spacing-lg);
  border-top: 1px solid $stroke;
  margin: $spacing-lg auto;

  &.no-border {
    border-top: 0 none;
    margin-top: $spacing-xs;
  }
}

.section {
  display: grid;
  width: 100%;
  max-width: $wrapper-width;
  margin: 0 0 $spacing-xl;
  gap: $spacing-sm;

  &.affiliate {
    max-width: unset;

    .section--title {
      width: 100%;
      max-width: calc($wrapper-width + 1.5rem);
      margin: 0 auto;
    }
  }

  @include screen-size('large') {
    margin: 0 auto $spacing-xl;
  }

  &--title {
    @include display-sm;
    @include text-bold;

    padding: 0 $spacing-sm;
    margin-bottom: 0;

    @include screen-size('custom', 1352px) {
      padding: 0;
    }
  }
}

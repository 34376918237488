.container,
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  -ms-flex-positive: 1;
  -ms-flex-preferred-size: 0;
}

.col-2 {
  max-width: 16.666667%;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
}

.col-3 {
  max-width: 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.333333%;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
}

.col-6 {
  max-width: 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.col-8 {
  max-width: 66.666667%;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
}

.col-9 {
  max-width: 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.333333%;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
}

.col-11 {
  max-width: 91.666667%;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
}

.col-12 {
  max-width: 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.order-3 {
  order: 3;
  -ms-flex-order: 3;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
  }
  .col-md-2 {
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
  }
  .col-md-3 {
    max-width: 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .col-md-4 {
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
  }
  .col-md-5 {
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
  }
  .col-md-6 {
    max-width: 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .col-md-7 {
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
  }
  .col-md-8 {
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
  }
  .col-md-9 {
    max-width: 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }
  .col-md-10 {
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
  }
  .col-md-11 {
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
  }
  .col-md-12 {
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .order-md-1 {
    order: 1;
    -ms-flex-order: 1;
  }
  .order-md-2 {
    order: 2;
    -ms-flex-order: 2;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
  }
  .col-lg-2 {
    max-width: 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
  }
  .col-lg-3 {
    max-width: 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .col-lg-4 {
    max-width: 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
  }
  .col-lg-5 {
    max-width: 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
  }
  .col-lg-6 {
    max-width: 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .col-lg-7 {
    max-width: 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
  }
  .col-lg-8 {
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
  }
  .col-lg-9 {
    max-width: 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }
  .col-lg-10 {
    max-width: 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
  }
  .col-lg-11 {
    max-width: 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
  }
  .col-lg-12 {
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .order-lg-1 {
    order: 1;
    -ms-flex-order: 1;
  }
  .order-lg-2 {
    order: 2;
    -ms-flex-order: 2;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-8 {
    max-width: 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
}

.search__results {
  display: grid;
  column-gap: $spacing-xs;
  container: search-results / inline-size;
  gap: $spacing-sm;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));

  .loco-snaps-open & {
    container: unset;
  }

  @include screen-size('custom', 1345px) {
    &:not(&.grid) {
      grid-template-columns: 1fr;
    }

    &.grid {
      gap: $spacing-md;
    }
  }

  &.search__results--no-results {
    grid-template-columns: 1fr;
  }
}

.container--search-loader {
  z-index: 10;
  width: 100%;
  max-width: none;
  min-height: 2415px;
  padding-right: 0;
  padding-left: 0;
  grid-column: 1 / -1;

  .loader__wrapper {
    z-index: 10;
    display: flex;
    min-height: 100%;
    align-items: flex-start !important;
    justify-content: center;
    padding-top: 125px !important;
    margin-top: 0 !important;
  }
}

.search-pagination {
  grid-column: 1 / -1;

  nav {
    padding: 0;

    @include screen-size('medium') {
      padding: 0 0 0 1.5rem;
    }
  }
}

.mobile-filters {
  position: sticky;
  z-index: 10;
  top: 0;
  display: grid;
  width: 100%;
  align-items: center;
  padding: 0.25rem 1rem;
  margin: 0 auto;
  background-color: $background-backdrop;
  gap: $spacing-xs;
  grid-template-columns: 1fr auto;

  @include screen-size('medium') {
    display: none;
  }
}

.mobile-filters-sheet {
  padding: $spacing-sm;
}

.mobile-filters-sheet-header {
  display: grid;
  align-items: center;
  padding: 0 $spacing-sm;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;

  @include screen-size('small') {
    padding: 0;
  }

  h2 {
    @include text-lg;
    @include text-bold;

    margin: 0;
  }

  .header-button {
    @include text-normal;

    color: $button-anchor-light !important;

    &:hover {
      color: $button-anchor-light !important;
    }
  }
}

.mobile-filters-sheet-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-sm;

  @include screen-size('small') {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 0.0625rem solid $fill-inactive-light;
    background-color: $white;
  }

  .clear-button:disabled {
    opacity: 0;
  }
}

.search__container {
  display: grid;
  gap: 0.75rem;

  .search__filter-button[data-hierarchy='filled'] {
    &,
    &:hover {
      background-color: $system-black;
    }
  }

  @include screen-size('medium') {
    padding-top: $spacing-sm;
    padding-left: $spacing-lg;
    gap: $spacing-sm;
    grid-column: 2 / -1;

    .mobile-only {
      display: none;
    }
  }

  @include screen-size('large') {
    max-width: 1160px;
    padding-left: 2.5rem;
    grid-column: 3/4;
    grid-template-columns: 1fr;
  }
}

.container--search-results {
  width: 100%;
  margin: 0 auto;
}

.search__filter-controls {
  position: sticky;
  top: 0;
  display: none;
  height: 100dvh;
  padding-top: $spacing-md;
  padding-bottom: 14rem;
  opacity: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  transition:
    opacity 250ms linear,
    transform 300ms linear,
    gap 250ms linear;

  @include screen-size('medium') {
    display: grid;
    min-width: 260px;
    grid-column: 1 / 2;
    grid-template-columns: repeat(auto-fill, minmax(0, 260px));
  }

  @include screen-size('large') {
    grid-column: 1 / 3;
    grid-template-columns: 1fr 300px;
  }

  &::-webkit-scrollbar {
    width: 0.325rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: $grey-100;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: $grey-400;
    }
  }
}

.container--search {
  @include screen-size('medium') {
    position: relative;
    display: grid;
    min-height: 100vh;
    align-items: flex-start;
    padding: 0 0 2.5rem;

    &.hidden-filters {
      gap: 0;
      grid-template-columns: 0 1fr;

      .search__filter-controls {
        appearance: none;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-100%);
      }
    }
  }
}

@import 'buttons';

.container--terms {
  padding: 3rem 1.5rem;
  text-align: left;

  @include screen-size('medium') {
    padding: 3rem 0;
  }

  h1 {
    @include display-xs;
    @include text-normal;
    @include font-primary;
    margin-bottom: 1rem;
    color: $secondary-500;
  }

  h3 {
    @include text-lg;
    @include text-normal;
    @include font-secondary;
    margin-bottom: 1rem;
    color: $secondary-500;
  }

  p,
  ul,
  li,
  li::before,
  ol {
    @include text-md;
    @include text-normal;
    @include font-secondary;
  }

  ol > li {
    margin-bottom: 0.125rem !important;
  }

  ul {
    margin-bottom: 2rem;
    list-style: '- ';
  }

  ol,
  ul {
    padding-left: 1.25rem;

    @include screen-size('medium') {
      padding-left: 2rem;
    }
  }

  .claim-button {
    @include button-lg;
    @include button-filled;
    width: 100%;
    justify-content: center;

    @include screen-size('medium') {
      width: fit-content;
    }
  }
}

.container--loading {
  position: relative;
  display: flex;
  min-height: 5029px;
  flex-direction: column;

  @include screen-size('medium') {
    min-height: 2427px;
  }
}

.search-results__applied-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .applied-filters {
    justify-content: center;
    padding: 0 1.5rem;
  }
}

.search-pills {
  width: 100%;
  max-width: 1460px;
  margin: 0 auto;

  @include screen-size('custom', 1181px) {
    width: calc(100dvw - 10rem);
  }
}

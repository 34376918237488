@import 'shadows';

.container--why-lease {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include screen-size('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  h1 {
    @include text-bold;
    @include font-primary;

    margin-bottom: 1rem;
    color: $secondary-500;
    font-size: 2rem;
  }

  .image-disclaimer {
    top: auto;
    bottom: 0 !important;
  }
}

.why-lease__image-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include screen-size('small') {
    flex-direction: row;
  }
}

.why-lease__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0;

  @include screen-size('medium') {
    padding: 0;
  }

  .pink {
    @include text-bold;

    color: $secondary-500;

    &.image-disclaimer {
      @include text-normal;

      color: $grey-900;
      font-size: 0.625rem;
    }
  }

  .text-large {
    @include text-bold;

    color: $secondary-500;
    font-size: 1.75rem;
  }

  > p {
    margin: 0.5rem 0;
  }

  a.link {
    color: $secondary-500;

    &:hover {
      color: $secondary-500;
    }
  }

  ol {
    li {
      margin-bottom: 0.25rem;

      &::before {
        @include text-normal;

      }
    }
  }

  .table {
    @include shadow-lg;

    width: 100%;
    border-width: 0.125rem !important;
    border-style: solid !important;
    border-color: $primary-500;
    border-radius: 0.25rem;
    background: $white;
    border-collapse: separate;
    border-spacing: 0;

    @include screen-size('small') {
      width: 95%;
    }

    @include screen-size('medium') {
      width: 85%;
    }

    tr {
      cursor: auto;

      &:hover {
        border-right: none;
        background-color: transparent;
      }
    }

    td {
      padding-left: 0.75rem;

      &.background-grey {
        @include text-bold;


        background-color: $system-grey-5;
      }
    }

    caption {
      @include text-normal;


      padding: 0;
      margin-bottom: 0.125rem;
      caption-side: top;
      color: $grey-900;
    }
  }

  .table-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    @include screen-size('small') {
      flex-direction: row;
    }
  }
}

.why-lease__container--white {
  ol,
  p {
    @include text-normal;
  }

  a {
    @include text-bold;
  }
}

.alternate-base-layer {
  @include orange-radial-gradient-background;

  .text-large {
    color: $secondary-500;
  }

  span {
    color: $secondary-500;
  }
}

.container--verify {
  h1,
  h2 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 20px;
    color: $secondary-500;
    font-size: 36px;
    line-height: 36px;
  }

  input {
    width: 100%;
    height: 40px;
    padding: 0.75rem;
    border: 1px solid $secondary-500;
    border-radius: 0.25rem;
    margin: 0 auto;

    @include screen-size('small') {
      width: 90%;
      max-width: 250px;
    }
  }

  .login__links {
    width: 90%;
    max-width: 250px;
  }

  a {
    color: $secondary-500;

    &:hover {
      color: $secondary-500;
    }
  }

  .register__link {
    padding: 0;
    border: 0;
    background-color: transparent;
    color: $secondary-500;

    &:hover {
      color: $secondary-500;
      text-decoration: underline;
    }
  }
}

.loading {
  @include button-loading-animation;
}

.container--reset-password {
  p.text-danger {
    margin-right: 10%;
  }
}

.container--tv {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include screen-size('medium') {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  h2 {
    @include text-bold;
    @include display-xs;

    margin-bottom: 1rem;
  }

  h3 {
    @include text-bold;
    @include text-lg;

    margin-top: 1rem;
  }

  p {
    @include text-normal;
    @include text-lg;

    margin-bottom: 0.75rem;

    &.small-text {
      @include text-xs;
    }
  }
}

.tv-explainer__image-container {
  display: none;
  height: 100%;

  @include screen-size('medium') {
    display: block;
  }
}

.tv-explainer__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;

  @include screen-size('medium') {
    margin: 2rem 0 0;
  }

  a {
    color: $primary-pink;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    gap: 0.25rem;
    list-style-type: none;

    li {
      @include text-normal;
      @include text-md;

      span {
        @include text-bold;
      }
    }

    &.sub-list {
      @include text-normal;

      font-size: 0.875rem;
    }
  }
}

.tv-explainer__button {
  margin-top: 2rem;
}

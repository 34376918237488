.error-boundary-page {
  min-height: 600px;
  flex: 1 0 auto;
  align-items: flex-start;
  justify-content: center;
  background-color: $grey-100;
  text-align: center;

  h2 {
    @include text-bold;
    @include font-primary;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    color: $secondary-500;
    font-size: 46px;
    line-height: 42px;
    text-transform: uppercase;

    @include screen-size(small) {
      font-size: 90px;
      line-height: 86px;
    }

    @include screen-size(medium) {
      font-size: 136px;
      line-height: 124px;
    }
  }

  p {
    @include text-bold;
    @include font-secondary;
    width: 75%;
    margin: 0 auto;

    color: $grey-900;
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include screen-size(small) {
      font-size: 36px;
      line-height: 36px;
    }

    @include screen-size(medium) {
      font-size: 46px;
      line-height: 46px;
    }
  }
}

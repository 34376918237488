.container--faq-article {
  max-width: 960px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;

  img {
    width: 100%;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  h1 {
    @include text-bold;
    @include font-primary;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    color: $secondary-500;
    font-size: 2rem;
    line-height: 2.25rem;
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 1rem;

    font-size: 1.125rem;
    line-height: 1.55rem;
  }

  a {
    @include text-bold;
    @include font-secondary;
    width: 100%;

    color: $secondary-500;
    font-size: 1.125rem;
  }

  ol {
    padding: 0;
    margin: 1rem 0 0;
  }

  .tag {
    display: inline-block;
    padding: 5px 20px;
    border-radius: 0.75rem;
    margin-top: 10px;
    margin-right: 10px;
    background-color: $system-grey-5;
    text-transform: capitalize;
  }
}

.container--blog {
  @media screen and (min-width: 576px) and (max-width: 768px) {
    max-width: 768px !important;
  }
  text-align: left;

  h1 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 2rem;
    color: $secondary-500;
    font-size: 2rem;
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 2rem;
    color: $grey-900;
    line-height: 1.4rem;

    a {
      @include text-bold;
      color: $secondary-500;
    }
  }
}

.masonry-grid {
  display: flex;

  /* gutter size offset */
  width: auto;
  margin-bottom: 4rem;
  margin-left: -30px;
}

.masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;

  > div {
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 30px;
    background-color: $system-grey-5;
  }

  img {
    display: block;
    width: 100%;
    min-height: 129px;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  h2 {
    @include text-bold;
    @include font-secondary;
    margin-bottom: 1rem;
    color: $grey-900;
    font-size: 1.3rem;
    line-height: 1.45rem;
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  a {
    width: 100%;
    font-size: 1.125rem;
  }
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

small {
  font-size: 80%;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href], [tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href], [tabindex]):hover,
a:not([href], [tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href], [tabindex]):focus {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

hr {
  overflow: visible;
  height: 0;
  box-sizing: content-box;
  border: 0;
  border-top: 1px solid rgb(0 0 0 / 10%);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  border-style: none;
  vertical-align: middle;
}

button {
  svg {
    overflow: hidden;
    pointer-events: none;
    vertical-align: middle;
  }
}

a {
  svg {
    overflow: hidden;
    pointer-events: none;
    vertical-align: middle;
  }
}

table {
  border-collapse: collapse;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

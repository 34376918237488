.container--loco-score {
  padding-top: 2rem;
  padding-bottom: 5rem;

  h1 {
    @include text-bold;
    @include font-primary;

    margin-bottom: 1rem;
    color: $secondary-500;
  }

  a {
    @include text-bold;
    @include font-secondary;

    color: $secondary-500;
  }

  .carousel-img-placeholder {
    width: 65%;
  }

  .card-shadow-lg {
    padding: 0.75rem;
    margin: 1.25rem 0 3rem;
    text-align: left;

    .home__h4-heading {
      @include text-bold;
      @include font-primary;

      color: $secondary-500;
    }

    p {
      @include text-normal;
      @include font-secondary;

      margin-bottom: 10px;
      line-height: 1.25rem;
    }
  }

  .tick-image-container {
    margin: 1rem 0;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .paragraph-content {
    @include text-normal;
    @include font-secondary;

    line-height: 1.25rem;
  }

  .loco-score-animation-background {
    width: 55%;
    margin: 0 auto;

    @include screen-size('small') {
      width: 100%;
      margin: 0;
    }
  }

  .loco-score-animation {
    position: absolute;
    top: 0;
    left: calc(50vw);
    min-height: 260px;

    @include screen-size('extra-small') {
      left: 16.75rem;
      min-height: 280px;
    }

    @include screen-size('small') {
      top: 0;
      left: 7.5rem;
      min-height: 220px;
    }

    @include screen-size('medium') {
      top: 0;
      left: 10rem;
      min-height: 280px;
      margin-top: 50px;
    }

    @include screen-size('large') {
      top: 0;
      left: 10rem;
      min-height: 190px;
    }

    @include screen-size('extra-large') {
      top: 0;
      left: 12rem;
      min-height: 250px;
    }

    span {
      @include text-bold;
      @include font-secondary;

      position: absolute;
      top: 50%;
      left: 50%;
      padding-bottom: 20px;
      color: #df342f;
      font-size: 90px;
      transform: translate(-50%, -50%);

      @include screen-size('medium') {
        font-size: 114px;
      }
    }
  }
}

.explainer__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid $stroke;

  @include screen-size('medium') {
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
}

.explainer__text {
  @include text-normal;
  @include font-secondary;

  width: 100%;
  padding-top: 1rem;
  font-size: 1rem;
  line-height: 1.25rem;

  @include screen-size('medium') {
    width: 80%;
    padding: 1rem;
  }
}

.explainer__loco-score {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;

  span {
    scale: 1.1;
  }
}

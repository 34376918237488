.container--search,
.container--content {
  width: 100%;
  max-width: none;
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;

  @include screen-size('medium') {
    max-width: 1460px;
  }

  @include screen-size('large') {
    max-width: unset;
  }
}

.container--search {
  padding: 0 $spacing-sm 5rem;

  @include screen-size('medium') {
    padding: 0;
    grid-template-columns: auto 260px 1fr auto;
  }

  @include screen-size('custom', 1181px) {
    max-width: unset;
    grid-template-columns: auto 300px calc(100dvw - 10rem - 300px) auto;
  }

  @include screen-size('custom', 1620px) {
    grid-template-columns: auto 300px 1160px auto;
  }
}

.container--content,
.container--body {
  flex: 1 0 auto;

  .content-breadcrumbs {
    padding: 0 $spacing-sm;

    @include screen-size('medium') {
      padding: 0 $spacing-md;
    }
  }
}

.container--header,
.container--footer {
  flex: none;
}

.container--page-loader {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  background-color: $white;
}

.container--loader {
  min-height: 400px;
  text-align: center;
}

.container--page-loader {
  display: flex;
  height: 100vh;
  flex: 1 1 100%;
  padding-top: 4rem;
}

.container--search-page {
  z-index: 15;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: $background-backdrop;
  gap: $spacing-xs;

  &:has(.search-pills:empty) {
    gap: 0;
  }

  @include screen-size('medium') {
    padding: 0 1.5rem;
  }

  @include screen-size('custom', 1181px) {
    padding: 1rem 0 0;
  }
}

.account-details {
  max-width: 100%;
  min-height: 50vh;
  padding: 0;
  background-color: $background-backdrop;
  text-align: left;

  .unauthenticated {
    display: grid;
    padding: $spacing-md;
    grid-template-columns: auto;
    place-items: center;

    > span {
      display: none !important;
    }

    @include screen-size('small') {
      padding: $spacing-lg;
      grid-template-columns: auto auto;

      > span {
        display: block !important;
      }
    }
  }
}

.container--guides {
  flex: 1 0 auto;
  padding-bottom: 5rem;

  h1 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 2rem !important;

    color: $secondary-500;
    font-size: 2rem;
  }

  h3 {
    @include text-bold;
    @include font-primary;

    color: $secondary-500;
    font-size: 1.5rem;
  }

  .guides__card {
    display: flex;
    width: 100%;
    height: 100%;

    a {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-radius: 0.5rem;
      margin-bottom: 1.5rem;
      box-shadow: var(--ring-offset-shadow, 0 0 transparent), var(--ring-shadow, 0 0 transparent),
        0 1px 3px 0 rgb(0 0 0 / 25%), 0 1px 2px 0 rgb(0 0 0 / 10%);

      @include screen-size('small') {
        margin-bottom: 2rem;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .filter-arrow {
      width: 8px;
      height: 16px;
    }

    .guides__card__cta {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding: 2rem 1rem 1rem;

      .button-cta {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        align-self: flex-end;
        order: 1;
        padding: 0.35rem 0.75rem;
        border-radius: 0.25rem;
        background: transparent;
        color: $secondary-500;
        font-size: 0.875rem;
      }
    }

    img {
      width: 100%;
      border-radius: 0.25rem 0.25rem 0 0;
    }

    &.guides__card--alternative {
      @include brand-radial-gradient-background;
      height: 255px;

      border-radius: 0.5rem;
      margin-bottom: 1.5rem;

      @include screen-size('extra-small') {
        height: 270px;
      }

      @include screen-size('small') {
        height: 330px;
      }

      @include screen-size('medium') {
        height: 129px;
        margin-bottom: 0;
      }

      @include screen-size('extra-large') {
        height: 158px;
      }

      @include screen-size('medium') {
        .button-cta {
          position: absolute;
          top: -1rem;
        }
      }

      @include screen-size('extra-large') {
        .button-cta {
          position: relative;
        }
      }

      .button-cta {
        background-color: $white;
        color: $secondary-500;
      }

      &.secondary {
        height: 255px;

        @include screen-size('extra-small') {
          height: 275px;
        }

        @include screen-size('small') {
          height: 330px;
        }

        @include screen-size('medium') {
          height: 109px;

          .button-cta {
            position: absolute;
            top: -2rem;
          }
        }

        @include screen-size('large') {
          .button-cta {
            position: relative;
            top: -3.75rem;
          }
        }

        @include screen-size('extra-large') {
          height: 135px;

          .button-cta {
            position: relative;
          }
        }
      }

      a {
        margin: 0;
      }

      .guides__card__content {
        width: 100%;

        h2 {
          margin-bottom: 0.5rem;
          color: $white;
        }

        p {
          @include text-bold;
          @include font-secondary;

          color: $white;
        }
      }
    }

    .guides__card__content {
      flex-grow: 1;
      padding: 1rem 1rem 0;
      text-align: left;

      h2 {
        @include text-bold;
        @include font-primary;
        margin-bottom: 0;

        color: $grey-900;
        font-size: 1rem;
        line-height: 1.2;

        @include screen-size('medium') {
          font-size: 1.125rem;
        }

        @include screen-size('extra-large') {
          font-size: 1.325rem;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 10px;

    color: $grey-900;
    line-height: 1.4rem;

    &.text-semibold {
      @include text-bold;
    }
  }

  ul {
    a {
      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .guides__video-container {
    padding: 2rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    background-color: $grey-900;
  }

  .alert {
    display: flex;
    align-items: center;

    p {
      @include text-bold;
      @include font-secondary;

      margin-bottom: 0;
    }
  }
}

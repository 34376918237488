*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  @include text-bold;

  display: flex;
  overflow: hidden auto;
  min-height: 100vh;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: $white;
  color: $grey-900;
  -webkit-font-smoothing: antialiased;
  text-align: left;
}

html,
body {
  @include font-secondary;

  width: 100%;
  font-family: sans-serif;
  font-size: $base-font-size;
  line-height: 1.15;
  scroll-behavior: auto !important;

  :focus {
    outline: 0;
  }

  :focus-visible {
    outline: 4px dotted;
  }
}

.loco-snaps-open {
  overflow: hidden;

  .one-tap-component,
  header {
    display: none;
  }
}

.stop-scroll {
  position: relative;
  overflow: hidden;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.global-scroll-area {
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
}

// Adjusting global-scroll-area to take message banner into account
[data-is-visible='true'] + .global-scroll-area {
  min-height: calc(100dvh - 2.5rem);
}

select,
select:focus {
  @include text-bold;
  @include font-secondary;

  padding-left: 0.5rem;
  border-radius: 0.25rem;
  appearance: none;
  background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+')
    no-repeat 95% 50%;
  background-color: $white;
  color: $grey-900;
  font-size: 1rem;

  @include screen-size('medium') {
    padding-left: 0.3rem;
  }

  @include screen-size('large') {
    padding-left: 0.5rem;
  }
}

p {
  margin-bottom: 0;
}

p,
a {
  @include font-secondary;
}

button {
  @include text-bold;
  @include font-primary;

  border: 0;
  appearance: none;
  background-color: transparent;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.text-danger {
  color: $danger;
}

.main {
  background-color: $white;
}

.form-control,
.form-control:focus {
  color: $grey-900;
  font-size: 1rem;

  @include screen-size('medium') {
    font-size: 0.75rem;
  }

  @include screen-size('large') {
    font-size: 0.875rem;
  }
}

a,
a:hover {
  cursor: pointer;
}

strong {
  @include text-bold;
}

mark {
  background-color: transparent;
  color: $grey-900;
}

.text-semibold {
  @include text-bold;
}

hr {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.loading-animation {
  @include button-loading-animation;
}

.slider-next-arrow {
  @media screen and (width >= 767px) {
    right: -25px;
  }

  position: absolute;
  z-index: 20;
  top: 0;
  right: -15px;
  display: block;
  width: 44px;
  height: 100%;
  border: 0;
  appearance: none;
  background: transparent url('https://assets.leaseloco.com/icons/library/arrow-right.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 33%;

  @include screen-size('ipad') {
    right: -15px;
  }
}

.slider-prev-arrow {
  @media screen and (width >= 767px) {
    left: -25px;
  }

  position: absolute;
  z-index: 20;
  top: 0;
  left: -15px;
  display: block;
  width: 44px;
  height: 100%;
  border: 0;
  appearance: none;
  background: transparent url('https://assets.leaseloco.com/icons/library/arrow-left.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 33%;

  @include screen-size('ipad') {
    left: -15px;
  }
}

.white-base-layer {
  background-color: $white;
}

// HotJar logo removal code on surveys
div[class$='__styles__surveyBranding'] {
  display: none !important;
}

.loader__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  margin-top: 60px;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.support-tab {
  position: fixed;
  z-index: 15;
  top: 50%;
  right: -39px;
  display: none;
  padding: 0.5rem 1.25rem;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: $primary-pink;
  transform: rotate(-90deg);

  @include screen-size(medium) {
    display: block;
  }

  p {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.slick-slide[aria-hidden='true'] > div {
  visibility: hidden;
}

.navbar-nav .slick-slide {
  opacity: 0.6 !important;
  transition: opacity 500ms ease-in-out !important;
}

.navbar-nav .slick-slide.slick-active {
  opacity: 1 !important;
  transition: opacity 500ms ease-in-out !important;
}

.love-electric-logo {
  @include text-bold;

  span {
    @include text-bold;
  }
}

.recharts-cartesian-axis-tick {
  @include text-sm;
  @include text-normal;
  @include font-secondary;
}

.recharts-tooltip-wrapper {
  > div > div {
    padding: 0;
  }
}

.image--reverse {
  transform: rotateY(180deg);
}

.icon--white {
  filter: $white-filter;
}

.icon--pink {
  filter: $primary-pink-filter;
}

// Zendesk styles to make the box sit in correct position
body > div > iframe {
  display: block;
}

iframe[title='Messaging window'],
iframe[title='Button to launch messaging window'],
iframe[title='Button to launch messaging window, conversation in progress'],
iframe[title='Button to launch messaging window, 1 unread message'],
iframe[title='Button to launch messaging window, 2 unread messages'],
iframe[title='Button to launch messaging window, 3 unread messages'],
iframe[title='Message from company'],
iframe[title='Number of unread messages'],
iframe[title='Close message'] {
  z-index: 1000 !important;
}

iframe[title='Messaging window'] {
  left: 0;
}

.alice-carousel {
  margin: 0;
}

header.overlay-open {
  ~ #toolbar-desktop {
    z-index: 5;
  }
}

.visx-tooltip {
  z-index: 1100000;
}

// reactjs-popup css
.popup-content {
  width: 50%;
  padding: 5px;
  margin: auto;
  background: $white;
}

.popup-arrow {
  color: $white;
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgb(0 0 0 / 16%) 0 0 3px;
}

[data-popup='tooltip'].popup-overlay {
  z-index: 20 !important;
  background: transparent;
}

.chevron-animation {
  &.chevron-down {
    span img {
      animation: rotateDown 0.3s ease-in-out;
    }
  }

  &.chevron-up {
    span img {
      animation: rotateUp 0.3s ease-in-out;
      transform: rotate(180deg);
    }
  }
}

@keyframes rotateDown {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateUp {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.container--faqs {
  flex: 1 0 auto;
  padding-bottom: 5rem;

  h1 {
    @include text-bold;
    @include font-primary;
    margin-bottom: 2rem !important;

    color: $secondary-500;
    font-size: 2rem;
  }

  h3 {
    @include text-bold;
    @include font-primary;

    color: $secondary-500;
    font-size: 1.5rem;
  }

  .faq__item {
    display: flex;
    flex-direction: column;

    @include screen-size('medium') {
      flex-direction: row;
    }
  }

  .faq__item__image {
    display: none;
    width: 240px;
    height: 180px;

    @include screen-size(medium) {
      display: block;
    }
  }

  .faq__item__copy {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 1rem;
    margin-left: 1rem;

    @include screen-size(medium) {
      width: calc(100% - 240px);
    }
  }

  a {
    @include text-bold;
    @include font-secondary;

    color: $secondary-500;
    text-decoration: none;

    &:hover {
      color: $secondary-500;
      text-decoration: underline !important;

      .faq__link {
        text-decoration: underline;
      }
    }
  }

  p {
    @include text-normal;
    @include font-secondary;
    margin-bottom: 10px;

    color: $grey-900;
    line-height: 1.4rem;

    &.text-semibold {
      @include text-bold;
    }
  }

  ul {
    a {
      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .faq__link {
    @include text-bold;
    @include font-secondary;

    color: $secondary-500;
    text-align: right;
  }

  .alert {
    display: flex;
    align-items: center;

    p {
      @include text-bold;
      @include font-secondary;

      margin-bottom: 0;
    }
  }

  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      padding: 0;
      border-bottom: 1px solid #aaa;
      margin: 0 0 10px;
    }

    &__tab {
      position: relative;
      bottom: -1px;
      display: inline-block;
      padding: 6px 12px;
      border: 1px solid transparent;
      border-bottom: none;
      cursor: pointer;
      list-style: none;

      &--selected {
        border-color: #aaa;
        border-radius: 5px 5px 0 0;
        background: #fff;
        color: black;
      }

      &--disabled {
        color: $background-backdrop;
        cursor: default;
      }

      &:focus {
        border-color: hsl(208deg 99% 50%);
        box-shadow: 0 0 5px hsl(208deg 99% 50%);
        outline: none;

        &::after {
          position: absolute;
          right: -4px;
          bottom: -5px;
          left: -4px;
          height: 5px;
          background: #fff;
          content: '';
        }
      }
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }

  .react-tabs__tab-list {
    padding: 0 0 1rem !important;
    border-color: rgb(0 0 0 / 10%);
    margin: 0 0 1.5rem !important;
  }

  .react-tabs__tab {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    background: $system-grey-5 !important;
  }

  .react-tabs__tab--selected {
    border-color: transparent;
    border-radius: 0;
    background: $secondary-500 !important;
    color: $white;
  }

  ul li {
    padding: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    font-size: 0.875rem !important;

    @include screen-size('medium') {
      padding: 1rem !important;
      margin-bottom: 0.75rem !important;
      font-size: 1rem !important;
    }
  }
}

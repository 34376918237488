.container--blog-article {
  display: flex;
  max-width: $wrapper-width;
  flex-direction: column;
  padding: 0 $spacing-sm;
  margin: 0 auto;

  @include screen-size('medium') {
    padding: 0;
  }
}

@import 'buttons';

.landing-page {
  padding: 0 1.5rem;

  &.deal-page {
    background-color: $background-backdrop;

    @include screen-size('medium') {
      margin-bottom: 0;
    }

    .header {
      padding: 0.75rem 0;

      @include screen-size('medium') {
        padding: 1.5rem 0;
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    margin: 0 auto;
    text-align: left;

    h1 {
      @include text-lg;
      @include text-bold;
      @include font-primary;

      margin-bottom: 0;
      color: $white;
    }

    .divider {
      width: 0.0625rem;
      height: 1.5rem;
      margin: 0 1rem;
      background-color: $grey-200;
    }

    .center {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: none;
      place-items: center;
      place-self: center;
    
      @include screen-size('medium') {
        display: grid;
      }
    }    
  }

  ~ .newsletter-banner__wrapper,
  ~ footer.container-fluid {
    display: none;
  }
}

.container--landing-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
  padding: 1.5rem 0;
  background-color: $grey-100;
  text-align: left;

  &.deal-page {
    background-color: $background-backdrop;
  }

  .body {
    flex: 1 0 auto;
  }

  .body--no-deal {
    @include text-md;
    @include text-semibold;

    .button {
      @include button-lg;
      @include button-filled;

      width: fit-content;
      margin-top: 1.5rem;
    }

    .no-deal-row {
      padding: 1.5rem;
      border-radius: 1.5rem;
      margin: 0 1.5rem;
      background: $white;

      @include screen-size('medium') {
        max-width: 768px;
        margin: 0 auto;
      }

      p {
        &:first-of-type {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .footer {
    padding: 2.25rem 1.5rem 1.5rem;
    text-align: left;

    @include screen-size('medium') {
      width: 68vw;
    }

    p {
      @include text-xs;
      @include text-semibold;

      color: $grey-800;

      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

.background--maroon {
  background-color: $secondary-maroon;
  
  .header {
    background-color: $secondary-maroon;
  }
}

.deal-landing-page__vehicle-make-model {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1.5rem;

  @include screen-size('medium') {
    flex-direction: row;
  }

  h1 {
    @include display-sm;
    @include text-bold;
  }

  .score__desktop {
    display: none;

    @include screen-size('medium') {
      display: block;
    }
  }

  .score__mobile {
    display: block;

    @include screen-size('medium') {
      display: none;
    }
  }

  .vehicle__stats {
    margin-top: 0.25rem;

    @include text-sm;
    @include text-semibold;

    @include screen-size('medium') {
      display: none;
    }
  }
}

.deal-landing-page__price {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 3.25rem;

  @include screen-size('medium') {
    align-items: center;
    margin-bottom: 0;
    gap: 1rem;
  }

  p {
    @include text-sm;
    @include text-semibold;

    color: $grey-900;

    @include screen-size('medium') {
      @include text-md;

      color: $grey-800;
    }

    &:first-of-type {
      @include text-md;
      @include text-bold;

      margin-bottom: 0.25rem;
      color: $grey-900;

      @include screen-size('medium') {
        margin-bottom: 0;
      }
    }
  }
}

.deal-landing-page__vehicle-details {
  padding: 0 1.5rem;
}

.deal-landing-page__benefits {
  display: flex;
  overflow: scroll;
  width: 100%;
  flex-direction: row;
  align-self: flex-start;
  padding: 0 1.5rem;
  gap: 0.5rem;
  justify-items: flex-end;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include screen-size('medium') {
    overflow: auto;
    width: fit-content;
    flex-direction: column;
    align-self: flex-end;
    padding: 0 1.5rem 0 0;
  }

  p {
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    background-color: $grey-200;
    color: $grey-900;
    white-space: nowrap;
  }

  div {
    display: flex;
    gap: 0.5rem;

    &:last-of-type {
      justify-content: flex-end;
    }
  }
}

.deal-landing-page__main {
  display: flex;
  flex-direction: column;
  padding: 0;

  @include screen-size('medium') {
    flex-direction: row;
    padding: 0 1.5rem;
    gap: 4.5rem;
  }
}

.deal-landing-page__vehicle-data {
  position: absolute;
  right: 0;
  bottom: 3rem;
  left: 0;
  display: flex;
  max-width: 29rem;
  padding: 1rem;
  border-radius: 1rem;
  margin: 0 auto;
  backdrop-filter: blur(25px);
  background: rgb(0 0 0 / 40%);
  gap: 0.5rem;
  text-align: center;

  div {
    flex: 1 0 0;
  }

  svg {
    margin-bottom: 0.5rem;
  }

  p {
    @include text-md;
    @include text-semibold;

    color: $white;

    &:last-of-type {
      @include text-xs;

      color: $white;
    }
  }
}

.deal-landing-page__vehicle-image {
  position: relative;
  flex-shrink: 0;
}

.gallery__container {
  position: relative;
  display: none;
  width: 100%;
  max-width: 68vw;
  text-align: center;

  @include screen-size('medium') {
    display: block;
  }

  .slick-list {
    margin: 0;
  }

  .vehicle-gallery {
    padding: 0;
  }

  img {
    border-radius: 1.5rem;
  }

  .image-disclaimer {
    @include text-md;
    @include text-semibold;

    color: $grey-800;
  }
}

.slider-next-chevron {
  position: absolute;
  z-index: 20;
  top: 0;
  right: 1.5rem;
  bottom: 0;
  display: block;
  width: 3.375rem;
  height: 100%;
  height: 3.375rem;
  border: 0;
  border-radius: 100px;
  margin: auto;
  appearance: none;
  backdrop-filter: blur(25px);
  background: rgb(0 0 0 / 40%);

  @media screen and (width >= 767px) {
    right: 1.5rem;
  }

  @include screen-size('ipad') {
    right: 1.5rem;
  }
}

.slider-prev-chevron {
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 1.5rem;
  display: block;
  width: 3.375rem;
  height: 100%;
  height: 3.375rem;
  border: 0;
  border-radius: 100px;
  margin: auto;
  appearance: none;
  backdrop-filter: blur(25px);
  background: rgb(0 0 0 / 40%);

  @media screen and (width >= 767px) {
    left: 1.5rem;
  }

  @include screen-size('ipad') {
    left: 1.5rem;
  }
}

.gallery__mobile {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0 1.5rem 1.5rem;
  gap: 0.5rem;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include screen-size('medium') {
    display: none;
  }

  span {
    flex-shrink: 0;
  }

  img {
    border-radius: 1.5rem;
  }
}

.deal-landing-page__enquiry-wrapper {
  padding: 0;
}

.deal-landing-page__score {
  scale: 1.414;
}

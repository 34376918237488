.dropdown {
  position: relative;
  z-index: 5;
}

.dropdown--navigation {
  display: flex;
  padding: 0.125rem 0.6875rem 0.125rem 0.125rem !important;
  border: 0.0625rem solid $fill-inactive-light;
  border-radius: 2.5rem;
  gap: 0.25rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $fill-disabled-light;
  }
}

.dropdown__navigation--dark {
  color: $white;
}

.dropdown__navigation--light {
  color: $label-text;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  margin-left: 0.255em;
  content: '';
  vertical-align: 0.255em;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000005;
  top: 100%;
  left: 0;
  display: none;
  min-width: 10rem;
  padding: 0.5rem;
  border: 0;
  border-radius: 0.25rem;
  margin: 0.125rem 0 0;
  background-clip: padding-box;
  background-color: $white;
  color: $grey-900;
  float: left;
  font-size: 1rem;
  list-style: none;
  text-align: left;

  @include screen-size(medium) {
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  }

  .dropdown__columns {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .nav-link {
    @include text-sm;
    @include text-bold;
    width: 100%;
    min-height: 2.5rem !important;

    color: $grey-900;
    text-align: center;

    @include screen-size(medium) {
      text-align: left;

      &:hover {
        border-radius: 0.25rem;
        background-color: $system-grey-5;
        color: $primary-pink;
      }

      &.active {
        color: $primary-pink;
      }
    }
  }

  .dropdown-divider {
    border-top: 1px solid $system-grey-5;
  }

  .dropdown__column {
    display: inline-flex;
    width: 100%;
    flex-direction: column;

    &.dropdown__column--manufacturers {
      padding-left: 0.5rem;
      border-left: 1px solid $system-grey-5;
      margin-left: 0.5rem;
    }
  }
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  overflow: hidden;
  height: 0;
  border-top: 1px solid #e9ecef;
  margin: 0.5rem 1.5rem;

  @include screen-size('medium') {
    margin: 0.5rem auto;
  }
}

.dropdown-menu.show {
  display: block;
}

.nav {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0;

  @include screen-size('medium') {
    padding: 0.5rem 1rem;
  }
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  cursor: default;
  pointer-events: none;
}


@import 'buttons';
@import 'shadows';

.container--content-homepage {
  width: 100%;
  max-width: none;
  padding-right: 0;
  padding-left: 0;
  background-color: $grey-100;

  .section-affiliates[data-section-name='affiliates'] {
    padding: 0 0 $spacing-md;
  }

  .section-manufacturers {
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background: $grey-100;

    @include screen-size('medium') {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .manufacturers__slider {
      display: flex;
      overflow: scroll hidden;
      width: 100%;
      flex-wrap: nowrap;
      padding: 0 1.5rem;
      gap: 2.875rem;
      scroll-snap-type: x proximity;
      scrollbar-width: none;

      @include screen-size('medium') {
        padding: 0 1rem;
      }

      @include screen-size('content-web') {
        padding: 0 calc(50% - 660px);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .manufacturers__link {
      display: flex;
      width: 4.5rem;
      flex-direction: column;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      color: $grey-900;
      transition: all 200ms ease-in-out;

      &:hover {
        text-decoration: none;
      }

      p {
        @include text-sm;
        @include text-bold;
        @include font-secondary;

        white-space: nowrap;
      }
    }
  }

  // Faq styles
  .accordion__item {
    border-bottom: 1px solid $grey-200;

    &.accordion__item--last {
      border-bottom: 1px solid $white;
    }
  }

  .accordion__button {
    @include display-xs;
    @include text-normal;
    @include font-primary;

    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    background-color: $white;
    color: $grey-900;
  }

  .accordion__panel {
    @include text-lg;
    @include text-normal;
    @include font-secondary;

    padding: 0 0 1.5rem;
    color: $grey-900;

    a {
      @include text-normal;
    }
  }

  .accordion__button::before,
  .accordion__button[aria-selected='true']::before {
    display: none;
    width: 0;
    height: 0;
    border: 0;
    margin: 0;
  }

  .accordion__button::after {
    display: inline-block;
    content: url('https://assets.leaseloco.com/icons/library/chevron-down.svg');
    font-size: 1.5rem;
  }

  .accordion__button[aria-expanded='true']::after,
  .accordion__button[aria-selected='true']::after {
    display: inline-block;
    content: url('https://assets.leaseloco.com/icons/library/chevron-up.svg');
    font-size: 1.5rem;
  }
}

.section-cheapest-deals {
  @include screen-size('medium') {
    padding: 5rem 0 3rem;
  }
}

.carousel__container {
  position: relative;
  width: 100%;

  &:hover .slider__button:not(:disabled) {
    @include screen-size('medium') {
      transform: scale(1);
      visibility: visible;
    }
  }
}

.grid__column {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;

  @include screen-size('medium') {
    max-height: 35rem;
    margin: 0 1rem 1rem;

    &:last-of-type {
      margin-left: 0;
    }
  }
}

.grid__container {
  display: flex;
  max-width: 1320px;
  flex-direction: column;
  margin: 3rem 1.5rem;

  @include screen-size('medium') {
    flex-direction: row;
    margin: 0 1rem 0.875rem;
  }

  @include screen-size('content-web') {
    margin: 0 auto 0.875rem;
  }
}

.section-grid {
  display: flex;
  flex-direction: column;
  padding: 0;
  background: $grey-100;
  gap: 0.5rem;

  @include screen-size('medium') {
    gap: 1rem;
  }

  .grid__row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include screen-size('medium') {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }
  }

  .grid__column {
    margin: 0;
  }

  .grid__item {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: auto;
    min-height: 30rem;
    flex-grow: 1;
    padding: 3rem 1.5rem;

    @include screen-size('medium') {
      height: 35rem;
      padding: 3rem;
    }

    &.electric {
      height: 30rem;
      justify-content: flex-start;
      background: linear-gradient(45deg, $tertiary-blue 12.62%, $tertiary-green 87.88%);
      color: $white;

      @include screen-size('medium') {
        height: 35rem;
        margin-left: 1rem;
      }

      .button-filled {
        &:hover {
          background-color: $secondary-500;
        }
      }

      .grid__image {
        position: absolute;
        z-index: 0;
        right: -12.5rem;
        bottom: 1rem;
        display: flex;
        width: 480px;
        height: 320px;

        @include screen-size('medium') {
          right: -9.5rem;
          bottom: 0;
          width: 640px;
          height: 427px;
        }

        &.is-vans {
          transform: scaleX(-1);
        }
      }

      &::before {
        position: absolute;
        top: -3rem;
        right: -20rem;
        display: block;
        width: 510px;
        height: 510px;
        background-image: url('https://assets.leaseloco.com/homepage/cta-confetti-1.svg');
        background-repeat: no-repeat;
        background-size: cover;
        content: '';

        @include screen-size('medium') {
          top: -2.5rem;
          right: -10rem;
        }
      }
    }

    &.business {
      height: 30rem;
      justify-content: flex-start;
      background-color: $grey-900;
      color: $white;

      @include screen-size('medium') {
        height: 35rem;
        margin-right: 1rem;
      }

      .button-filled {
        background-color: $white;
        color: $grey-900;

        &:hover {
          background-color: $white;
          color: $grey-900;
        }

        img {
          filter: $grey-900-filter;
        }
      }

      .grid__image {
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: -11rem;
        display: flex;
        width: 480px;
        height: 320px;

        @include screen-size('medium') {
          right: -18rem;
          left: auto;
          width: 720px;
          height: 480px;
          transform: scaleX(-1);
        }
      }

      .grid__content {
        &::before {
          position: absolute;
          top: -10.5rem;
          right: -35rem;
          display: block;
          width: 718px;
          height: 326px;
          background-image: url('https://assets.leaseloco.com/homepage/cta-confetti-3.svg');
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
        }

        &::after {
          position: absolute;
          z-index: 0;
          bottom: -2.5rem;
          left: -4.5rem;
          display: block;
          width: 350px;
          height: 350px;
          background-image: url('https://assets.leaseloco.com/homepage/cta-confetti-2.svg');
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
        }
      }
    }

    .grid__content {
      position: relative;
      width: 100%;
      height: inherit;

      @include screen-size('medium') {
        max-width: 732px;
      }
    }

    h2 {
      @include display-lg;
      @include text-bold;
      @include font-primary;

      @include screen-size('medium') {
        @include display-xl;
      }
    }

    p {
      @include text-xl;
      @include text-bold;
      @include font-primary;
    }

    .button-text {
      @include button-lg;
      @include button-plain;
      @include text-bold;
      @include font-secondary;

      z-index: 10;
      color: $white;
      filter: $white-filter;
    }

    .button-filled {
      @include button-lg;
      @include button-filled;
      @include text-bold;
      @include font-secondary;

      z-index: 10;
    }
  }

  .grid__button-container {
    display: flex;
    margin-top: 2.5rem;
    gap: 0.5rem;
  }

  .grid__heading {
    width: 100%;
    padding: 0;

    @include screen-size('medium') {
      padding: 1rem 0;
    }

    h3 {
      @include display-xs;
      @include text-bold;
      @include font-primary;

      margin-bottom: 1.5rem;
      color: $grey-900;

      @include screen-size('medium') {
        margin-bottom: 0.5rem;
      }
    }
  }

  .grid__stats {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;

    @include screen-size('medium') {
      justify-content: flex-end;
      margin-top: 1rem;
      gap: 2.75rem;
    }

    p {
      @include text-sm;
      @include text-bold;
      @include font-secondary;

      color: $grey-800;
      line-height: 1.8125rem;

      &.gradient-text {
        @include text-gradient;
        @include display-xs;
        @include text-bold;
        @include font-primary;

        line-height: 1.75rem;

        &.alternative {
          @include text-purple-gradient;

          @include screen-size('medium') {
            @include text-gradient;
          }
        }
      }
    }
  }
}

.slider__button {
  @include button-filled;
  @include shadow-lg;

  position: absolute;
  z-index: 20;
  bottom: calc(50% - 22px);
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: $primary-pink;
  transform: scale(0);
  transition: all 300ms ease-in-out;
  visibility: hidden;

  &:disabled {
    visibility: hidden;
  }

  &.right {
    right: 2.8125rem;
  }

  &.left {
    left: 2.8125rem;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.section-customer-testimonials {
  position: relative;
  z-index: 5;
  overflow: hidden;
  min-height: 40rem;

  @include screen-size('medium') {
    margin-top: 9.5rem;
  }

  &::before {
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 15rem;
    left: calc(50% - 494px);
    display: block;
    width: 990px;
    height: 419px;
    background-image: url('https://assets.leaseloco.com/homepage/customer-confetti.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';

    @include screen-size('small') {
      bottom: 8rem;
    }
  }

  &::after {
    position: absolute;
    z-index: 0;
    display: block;
    width: 100%;
    background-image: url('https://assets.leaseloco.com/homepage/testimonials-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    inset: -2.5rem 0 0;

    @include screen-size('custom', 400px) {
      top: -5rem;
    }

    @include screen-size('extra-small') {
      top: -5rem;
      left: calc(50% - 756px);
      width: 1512px;
      height: 679px;
      margin: 0;
      background-image: url('https://assets.leaseloco.com/homepage/testimonials.png');
    }
  }

  .grid__container {
    padding-top: 7.5rem;

    @include screen-size('medium') {
      padding-top: 0;
    }
  }

  .grid__row {
    width: 100%;
  }

  .testimonial {
    z-index: 5;
    max-width: 45rem;

    .testimonial__image {
      @include shadow-xl;

      width: 10rem;
      height: 10rem;
      border-radius: 2.5rem;
      margin: 0 auto;

      @include screen-size('medium') {
        width: 15rem;
        height: 15rem;
      }

      img {
        border-radius: 2.5rem;
      }
    }

    .testimonial__description {
      @include text-xl;
      @include text-bold;
      @include font-primary;

      margin: 1.5rem 0 1rem;
    }

    .testimonial__name {
      @include text-md;
      @include text-bold;
      @include font-secondary;

      color: $grey-800;

      span {
        color: $primary-500;
      }
    }
  }

  .slick-slider {
    z-index: 5;
    text-align: center;
  }

  .slick-dots li {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border-radius: 100%;
    margin: 0 0.25rem;

    button {
      width: 0.5rem;
      height: 0.5rem;
      padding: 0;
      border-radius: 100%;
      background-color: $grey-200;

      &::before {
        content: '';
      }
    }

    &.slick-active {
      button {
        background-color: $primary-500;
      }
    }
  }
}

.section-media-cards {
  position: relative;
  z-index: 5;
  padding: 0 0 2rem;
  background-color: transparent;

  @include screen-size('medium') {
    padding: 2rem 0;
  }

  .media-cards__heading {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 2.5rem;
    text-align: center;

    @include screen-size('medium') {
      flex-direction: row;
      text-align: left;
    }
  }

  .media-cards__title {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include screen-size('medium') {
      flex-direction: row;
    }

    p {
      @include text-lg;
      @include text-normal;
      @include font-primary;

      color: $white;

      span {
        @include display-lg;
        @include text-bold;
        @include font-secondary;
      }
    }
  }

  .deal-slider {
    display: flex;
    overflow: scroll hidden;
    flex-wrap: nowrap;
    padding: 1.5rem 1.5rem 2rem;
    gap: 1rem;
    scroll-snap-type: x proximity;
    scrollbar-width: none;

    @include screen-size('medium') {
      min-height: 460px;
      padding: 1.5rem 1rem 2rem;
    }

    @include screen-size('content-web') {
      padding: 1.5rem calc(50% - 660px) 2rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.sell-your-vehicle {
  padding: 0;

  @include screen-size('medium') {
    padding: 0 $spacing-sm;
  }
}
